<template >
    <div>
        <topnav></topnav>
        <div class="page-wrap">
            <h1 class="txt160 serif light loading">
                {{ $lang === 'fr' ? $local.contactPage.titleFrench : $local.contactPage.title }}
            </h1>
            <div class="contact-top-box">
                <p class="contact-txt"> {{ $t('Let us know how we can be of service to you.') }}</p>
                <p class="contact-gray-txt">{{ $t('Choose your preferred method of communication and one of our specialists will get back to you asap.') }}
                </p>
            </div>
            <div class="contact-wrap">
                <div class="contact-box"><img
                        src="../assets/Contact-icon-1.svg"
                        class="contact-icon"
                    />
                    <p class="contact-txt">
                        {{ $t('Call us at') }}
                        <br />
                        <a :href="`tel:${$local.contactPage.phoneNumber}`">{{ $local.contactPage.phoneNumber }}</a>
                    </p>
                </div>
                <div class="contact-box"><img
                        src="../assets/Contact-icon-2.svg"
                        class="contact-icon"
                    />
                    <p class="contact-txt">
                        {{ $t('Chat with you by') }}
                        <br />
                        <a
                            :href="$local.contactPage.whatsapp"
                            target="_blank"
                        >WhatsApp</a>
                    </p>
                </div>
                <div class="contact-box"><img
                        src="../assets/Contact-icon-3.svg"
                        class="contact-icon"
                    />
                    <div class="form-wrap">
                        <p class="contact-txt">
                            {{ $t('Write us a message') }}</p>
                        <!-- class="hide-form" -->
                        <HsForm
                            class="hide-form"
                            @submitFunc="providedForm"
                            v-model="form"
                            region="eu1"
                            portalId="25499998"
                            formId="9e22727c-334a-4965-874a-1dbace694447"
                        ></HsForm>
                        <form
                            autocomplete="off"
                            @submit.prevent="submitForm"
                            v-if="formStatus != 'success'"
                            class="form"
                        >
                            <div>
                                <input
                                    :placeholder="$t('Name')"
                                    type="text"
                                    name="Name"
                                    class="txt-field"
                                    required=""
                                    v-model="form.firstname"
                                />
                                <input
                                    :placeholder="$t('Surname')"
                                    type="text"
                                    name="Surname"
                                    class="txt-field"
                                    required=""
                                    v-model="form.lastname"
                                />
                                <input
                                    :placeholder="$t('Email')"
                                    type="email"
                                    name="email"
                                    required=""
                                    class="txt-field"
                                    v-model="form.email"
                                />
                                <!-- <vue-tel-input
                                    :inputOptions="{ placeholder: $t('Phone') + '*', required: true }"
                                    mode="international"
                                    autocomplete="tel"
                                    class="txt-field"
                                    invalidMsg="Invalid phone number"
                                    :validCharactersOnly="true"
                                    v-model="form.phone"
                                ></vue-tel-input> -->

                                <vue-tel-input
                                    translate="no"
                                    :inputOptions="{ placeholder: 'Phone' + '*', required: true }"
                                    :dropdownOptions="{
                                        showDialCodeInSelection: true,
                                        showFlags: true,
                                        showSearchBox: true,
                                    }"
                                    :onlyCountries="['AL', 'AD', 'AT', 'BY', 'BE', 'BA', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FO', 'FI', 'FR', 'GE', 'DE', 'GR', 'HU', 'IS', 'IE', 'IT', 'XK', 'LV', 'LI', 'LT', 'LU', 'MT', 'MD', 'MC', 'ME', 'NL', 'MK', 'NO', 'PL', 'PT', 'RO', 'RU', 'SM', 'RS', 'SK', 'SI', 'ES', 'SE', 'CH', 'UA', 'GB', 'IL']"
                                    :autoFormat="true"
                                    type="tel"
                                    inputmode="numeric"
                                    defaultCountry="FR"
                                    autocomplete="tel"
                                    class="txt-field"
                                    invalidMsg="Invalid phone number"
                                    :validCharactersOnly="true"
                                    v-model="form.phone"
                                />


                            </div>
                            <div>
                                <textarea
                                    :placeholder="$t('Message')"
                                    class="txt-field big"
                                    v-model="form.message"
                                >
                            </textarea>
                                <input
                                    :disabled="submitting"
                                    type="submit"
                                    :value="$t('Submit')"
                                    class="form-btn"
                                />
                            </div>
                        </form>

                        <div
                            class="form-fail"
                            v-if="formStatus == 'err'"
                        >
                            <div
                                class="form-fail"
                                v-html="errMsg || $t('error occurred while sending')"
                            >
                            </div>
                        </div>
                        <div
                            class="form-success"
                            v-if="formStatus === 'success'"
                        >{{ $t('Thanks for submitting the form.')
                        }}
                            <br /> {{ $t('One of our team will contact you very soon.') }}
                        </div>
                        <!-- v-html="$t(successMessage) || $t('Thanks for submitting the form.') + '<br />' + $t('One of our team will contact you very soon.')" -->

                    </div>
                </div>
                <div class="contact-box"><img
                        src="../assets/Contact-icon-4.svg"
                        class="contact-icon"
                    />
                    <div>
                        <p class="contact-txt">
                            {{ $t('Get on a zoom call with us') }}
                        </p>
                        <a
                            :href="$local.contactPage.linkToSchedule"
                            class="schedule-btn"
                        >
                            {{ $t(' Click here to schedule') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <botfooter></botfooter>
    </div>
</template>



<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
import HsForm from '../components/HsForm.vue';
import { contactPage } from '@/data/localData.json'

import { VueTelInput } from 'vue-tel-input';
import '@/vue-tel-input.css';
export default {
    components: {
        VueTelInput,
        Topnav,
        Botfooter,
        HsForm
    },
    metaInfo() {
        return {
            ...this.seo({
                title: this.$lang === 'fr' ? contactPage.titleFrench : contactPage.title,
                description: this.$lang === 'fr' ? contactPage.meta_description_french : contactPage.meta_description
            }),
        };
    },
    data() {
        return {
            submitting: false,
            form: {
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                message: '',
            },
            errMsg: '',
            formStatus: '',
            submitFunc: null,
            successMessage: ''
        };
    },
    computed: {
        $local() {
            return { contactPage }
        }
    },
    methods: {
        providedForm($event) {
            this.submitFunc = $event
        },
        async submitForm() {
            if (this.submitting) return;
            try {
                this.submitting = true
                const res = await this.submitFunc()
                if (res.redirectUrl) window.location.href = res.redirectUrl
                this.successMessage = res.inlineMessage
                this.formStatus = 'success';
                this.submitting = false
            } catch (err) {
                this.errMsg = err
                this.formStatus = 'err';
                this.submitting = false
            }
        },

    },
    name: "Contact Us"
};
</script>